/** @jsxRuntime classic */
/** @jsx jsx */
import { IGatsbyImageData } from "gatsby-plugin-image"
import { graphql, PageProps } from "gatsby"
import { jsx } from "theme-ui"

import type { OurStoryPageQuery } from "../../graphql-types"
import { convertArticleData } from "helpers"

import FeaturedPosts from "components/FeaturedPosts"
import Layout from "components/Layout"
import OurStoryBlockMapper from "components/block-mappers/OurStoryBlockMapper"
import PageHero from "components/PageHero"

export const query = graphql`
  query OurStoryPage {
    heroImage: file(relativePath: { eq: "news-hero.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    timelineImage1: file(relativePath: { eq: "timeline1.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    timelineImage2: file(relativePath: { eq: "timeline2.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    timelineImage3: file(relativePath: { eq: "timeline3.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    timelineImage4: file(relativePath: { eq: "timeline4.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    timelineImage5: file(relativePath: { eq: "timeline5.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    allStrapiArticle(limit: 3) {
      edges {
        node {
          Publish_Date
          slug
          title
          article_type {
            slug
          }
        }
      }
    }
    strapiOurStory {
      blocks
      seo {
        shareImage {
          localFile {
            publicURL
          }
        }
        metaTitle
        metaDescription
        id
      }
      Hero {
        hero_links {
          link_button_url
          link_button_text
        }
        title
        content
        has_lines
        has_locator
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image_position
        subtitle
      }
    }
  }
`

interface OurStoryPageProps extends PageProps {
  data: OurStoryPageQuery
}

const OurStoryPage = ({ data, ...props }: OurStoryPageProps) => {
  const blogPosts = convertArticleData(data.allStrapiArticle?.edges)

  return (
    <Layout seo={data.strapiOurStory?.seo} location={props.location}>
      <PageHero
        title={data.strapiOurStory?.Hero?.title as string}
        subtitle={data.strapiOurStory?.Hero?.subtitle as string}
        content={data.strapiOurStory?.Hero?.content as string}
        hasLines={data.strapiOurStory?.Hero?.has_lines as boolean}
        hasLocatorCTA={data.strapiOurStory?.Hero?.has_locator as boolean}
        imagePosition={data.strapiOurStory?.Hero?.image_position as string}
        image={
          data.strapiOurStory?.Hero?.image?.localFile?.childImageSharp
            ?.gatsbyImageData as IGatsbyImageData
        }
        imageAlt={data.strapiOurStory?.Hero?.image?.alternativeText}
        buttonOneUrl={
          data.strapiOurStory?.Hero?.hero_links?.[0]?.link_button_url as string
        }
        buttonOneText={
          data.strapiOurStory?.Hero?.hero_links?.[0]?.link_button_text as string
        }
        buttonTwoUrl={
          data.strapiOurStory?.Hero?.hero_links?.[1]?.link_button_url as string
        }
        buttonTwoText={
          data.strapiOurStory?.Hero?.hero_links?.[1]?.link_button_text as string
        }
      />

      {data.strapiOurStory?.blocks.map((block: any, index: number) => (
        <OurStoryBlockMapper key={index} block={block} />
      ))}

      <FeaturedPosts posts={blogPosts} />
    </Layout>
  )
}

export default OurStoryPage
