/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react"
import { jsx, Flex, Container, Box } from "theme-ui"

import { getStrapiCorrectImageSrc } from "helpers/index"

import LinksBlock from "components/LinksBlock"
import MdContent from "components/common/MdContent"
import SectionHeading from "components/SectionHeading"
import TextImage50Split from "components/TextImage50Split"
import Timeline from "components/Timeline"

interface OurStoryBlockMapperProps {
  block: any
}

const OurStoryBlockMapper = ({ block }: OurStoryBlockMapperProps) => {
  switch (block.strapi_component) {
    case "our-story.event-section":
      return (
        <Box as="section" sx={{ my: [18, null, null, 40] }}>
          <SectionHeading>{block.title}</SectionHeading>
          <Container>
            <Box sx={{ maxWidth: "1100px", ml: "auto" }}>
              {block.event_description.map((item: any, index: number) => (
                <TextImage50Split
                  key={index}
                  title={item.title}
                  content={item.content}
                  normalImageSrc={getStrapiCorrectImageSrc(item.image.url)}
                  imageAlt={item.image.alternativeText}
                />
              ))}
            </Box>
          </Container>
        </Box>
      )
    case "our-story.content-with-link-list":
      const linkListData = block.link_list.map((item: any) => ({
        text: item.link_text,
        url: item.link_url,
      }))
      return (
        <Box as="section">
          <Box sx={{ my: 20 }}>
            <SectionHeading>{block.title}</SectionHeading>
            <Container>
              <Flex
                sx={{
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: 10,
                }}
              >
                <Box sx={{ width: "600px" }}>
                  <MdContent source={block.content} sx={{ mb: 9 }} />
                </Box>
                <LinksBlock heading={block.subtitle} links={linkListData} />
              </Flex>
            </Container>
          </Box>
        </Box>
      )
    case "our-story.timeline":
      const timelineData = block.event.map((item: any) => ({
        image: item.image,
        year: item.Year,
        content: item.eventDetails,
      }))

      return <Timeline timelineItems={timelineData} />
    default:
      return <></>
  }
}

export default OurStoryBlockMapper
