/** @jsx jsx */
import { IGatsbyImageData } from "gatsby-plugin-image"
import { Flex, Box, Heading, jsx } from "theme-ui"

import ImageWithDropshadow from "components/common/ImageWithDropshadow"
import MdContent from "components/common/MdContent"

interface TestImage50SplitProps {
  //enables sx prop to be passed down from parent
  className?: string
  content: string
  image?: IGatsbyImageData
  normalImageSrc?: string
  imageAlt: string
  title: string
}

const TestImage50Split = ({
  className,
  content,
  image,
  imageAlt,
  title,
  normalImageSrc,
}: TestImage50SplitProps) => {
  return (
    <Flex
      className={className}
      as="article"
      sx={{
        justifyContent: "space-between",
        flexDirection: ["column", null, null, "row"],
        mb: [14, null, null, 28],
        // alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          flex: ["1 100%", null, null, "0 1 45%"],
          mr: 6,
          mb: 8,
        }}
      >
        <Heading as="h1" variant="title">
          {title}
        </Heading>

        <MdContent source={content} />
      </Box>
      <Box
        sx={{
          flex: ["1 100%", null, null, "0 1 45%"],
          height: ["200px", null, null, "380px"],
        }}
      >
        {image && <ImageWithDropshadow image={image} imageAlt={imageAlt} />}

        {!image && normalImageSrc && (
          <Box
            className={className}
            sx={{
              position: "relative",
              display: "block",
              width: "100%",
              height: "100%",
              pb: [2.5, null, 4],
              pr: [2.5, null, 4],
              "&:after": {
                content: '""',
                bg: "primary",
                position: "absolute",
                bottom: 0,
                right: 0,
                width: ["calc(100% - .625rem)", null, "calc(100% - 1rem)"],
                height: ["calc(100% - .625rem)", null, "calc(100% - 1rem)"],
                zIndex: 0,
              },
            }}
          >
            <img
              src={normalImageSrc}
              alt={imageAlt}
              sx={{
                height: "100%",
                objectFit: "cover",
                position: "relative",
                verticalAlign: "middle",
                width: "100%",
                zIndex: 1,
              }}
            />
          </Box>
        )}
      </Box>
    </Flex>
  )
}

export default TestImage50Split
