/** @jsx jsx */
import React from "react"
import { jsx, ThemeUICSSObject } from "theme-ui"

interface YearProps {
  //enables sx prop to be passed down from parent
  className?: string
  front?: boolean
}

const Year = ({ className, front }: YearProps) => {
  const frontStyles: ThemeUICSSObject = front
    ? {
        transition: "stroke-dashoffset 0.7s linear, stroke 0.7s",
        strokeDasharray: 365,
        strokeDashoffset: 365,
      }
    : {
        transition: "fill 0.4s ease 0.4s",
      }

  return (
    <svg
      className="year-svg"
      viewBox="0 0 100 100"
      sx={{
        position: "absolute",
        width: "70px",
        height: "70px",
        top: "-35px",
        zIndex: 20,
        left: ["-20px", "-14px", null, "47.3%", null],
      }}
    >
      <circle
        cx="50"
        cy="50"
        r="40"
        fill={front ? "none" : "text"}
        stroke={front ? "none" : "#F5F3EC"}
        className={front ? "secondary" : "primary"}
        strokeWidth="6"
        sx={{
          ...frontStyles,
        }}
      ></circle>
    </svg>
  )
}

export default React.memo(Year)
