/** @jsx jsx */
import { Link } from "gatsby"
import { Flex, Heading, Text, jsx } from "theme-ui"

import Arrow from "components/common/Arrow"

export interface BlogCardProps {
  //enables sx prop to be passed down from parent
  className?: string
  date: string
  srcUrl: string
  title: string
}

const BlogCard = ({ className, date, title, srcUrl }: BlogCardProps) => {
  const fixedDate = new Date(date)
  const formattedDate = fixedDate.toLocaleDateString("en-us", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
  const dateTimeAttr = fixedDate.toLocaleDateString("en-us")

  return (
    <Link to={srcUrl} sx={{ textDecoration: "none", color: "text" }}>
      <Flex
        as="article"
        className={className}
        sx={{
          bg: "lightTan",
          boxShadow: 0,
          cursor: "pointer",
          flexDirection: "column",
          height: "100%",
          p: 5,
          position: "relative",
          width: "100%",
          "& > .card-link": {
            opacity: [1, null, null, 0],
            transition: "opacity 0.3s ease-in-out",
          },
          "&:hover .card-link": {
            opacity: 1,
          },
        }}
      >
        {date && (
          <time
            dateTime={dateTimeAttr}
            sx={{
              variant: "text.default",
              textTransform: "uppercase",
              mb: 1.5,
            }}
          >
            {formattedDate}
          </time>
        )}
        {title && (
          <Heading as="h1" variant="bodyTitle" sx={{ flex: 1, color: "text" }}>
            {title}
          </Heading>
        )}
        {srcUrl && (
          <Flex className="card-link" sx={{ variant: "buttons.textOnly" }}>
            <Text as="span" className="link-text">
              Read Article
            </Text>

            <Arrow arrowColor="primary" />
          </Flex>
        )}
      </Flex>
    </Link>
  )
}

export default BlogCard
