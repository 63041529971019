/** @jsx jsx */
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Box, jsx } from "theme-ui"

interface ImageWithDropshadowProps {
  //enables sx prop to be passed down from parent
  className?: string
  image: IGatsbyImageData
  imageAlt: string
}

const ImageWithDropshadow = ({
  className,
  image,
  imageAlt,
}: ImageWithDropshadowProps) => {
  return (
    <Box
      className={className}
      sx={{
        position: "relative",
        display: "block",
        width: "100%",
        height: "100%",
        pb: [2.5, null, 4],
        pr: [2.5, null, 4],
        "&:after": {
          content: '""',
          bg: "primary",
          position: "absolute",
          bottom: 0,
          right: 0,
          width: ["calc(100% - .625rem)", null, "calc(100% - 1rem)"],
          height: ["calc(100% - .625rem)", null, "calc(100% - 1rem)"],
          zIndex: 0,
        },
      }}
    >
      <GatsbyImage
        image={image}
        alt={imageAlt}
        sx={{
          height: "100%",
          width: "100%",
          zIndex: 1,
          position: "relative",
          objectFit: "cover",
        }}
      />
    </Box>
  )
}

export default ImageWithDropshadow
