/** @jsx jsx */
import gsap from "gsap"
import useWindowSize from "hooks/useWindowSize"
import { Box, Container, Flex, jsx } from "theme-ui"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { motion, useTransform, useViewportScroll } from "framer-motion"
import { useEffect, useState, useRef, useLayoutEffect } from "react"

import BackgroundSVG from "images/icons/background-light.svg"

import TimelineBlock from "components/TimelineBlock"
import Year from "components/Year"

gsap.registerPlugin(ScrollTrigger)

interface TimelineProps {
  //enables sx prop to be passed down from parent
  className?: string
  timelineItems: TimelineItems[]
}

type TimelineItems = {
  content: string
  year: string
  eventImage?: IGatsbyImageData
  imageAlt?: string
}

const Timeline = ({ className, timelineItems }: TimelineProps) => {
  const timelineRef = useRef<HTMLDivElement>(null)

  // Stores the start and end scrolling position for our container
  const [scrollPercentageStart, setScrollPercentageStart] = useState(0)
  const [scrollPercentageEnd, setScrollPercentageEnd] = useState(0)
  const [timeline, setTimeline] = useState(0)
  let timelineBlocks: HTMLCollection
  const { width: windowWidth, height: windowHeight } = useWindowSize()

  const { scrollY, scrollYProgress } = useViewportScroll()

  // Use the container's start/end position percentage
  const lineHeight = useTransform(
    scrollYProgress,
    [scrollPercentageStart, scrollPercentageEnd],
    ["0%", "100%"]
  )

  const yearRefs = useRef([])
  // const lineRef = useRef(null)
  yearRefs.current = []

  useEffect(() => {
    // Year and card animation.
    yearRefs.current.forEach(el => {
      gsap.from(el, {
        scrollTrigger: {
          trigger: el,
          scrub: false,
          start: "-60 52%",
          end: "bottom top",
          toggleClass: "active",
          // markers: true,
        },
      })
    })
  }, [])

  function getTimeLineLength() {
    if (!timelineRef.current) {
      return
    }

    const scrollTop = window.scrollY || document.documentElement.scrollTop
    const rect = timelineRef.current.getBoundingClientRect()
    const timelineLastChild = timelineRef.current.lastChild as Element
    const timelineLength =
      rect.height - timelineLastChild.getBoundingClientRect().height
    const offsetStart = rect.top + scrollTop
    // const elementScrollStart = offsetStart / 2 / document.body.clientHeight
    const midPoint = windowHeight / 2
    const elementScrollStart =
      (offsetStart - midPoint) / document.body.clientHeight
    const offsetEnd = offsetStart + timelineLength
    const elementScrollEnd = offsetEnd / document.body.clientHeight

    setTimeline(timelineLength)
    setScrollPercentageStart(elementScrollStart)
    setScrollPercentageEnd(elementScrollEnd)
  }

  const addToYearRefs = el => {
    if (el && !yearRefs.current.includes(el)) {
      yearRefs.current.push(el)
    }
  }

  useLayoutEffect(() => {
    document.fonts.ready.then(() => {
      getTimeLineLength()
    })
  }, [])

  useEffect(() => {
    getTimeLineLength()
  }, [windowHeight, windowWidth])

  return (
    <Box
      as="section"
      sx={{
        backgroundColor: "text",
        color: "textWhite",
        position: "relative",
        overflow: "hidden",
        pt: 24,
      }}
    >
      <BackgroundSVG
        sx={{
          position: "absolute",
          zIndex: 0,
          transformOrigin: "center",
          top: -56,
          right: 0,
          width: "auto",
          height: "1300px",
          transform: "translate(30%, 0)",
          "& > g": {
            opacity: 0.2,
          },
          "& *": {
            fill: "#d6d1c4cf",
          },
        }}
      />
      <Container sx={{ pt: 8 }}>
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: ["27px", "34px", null, "50.5%"],
              transform: "translateX(50%)",
              // transform: ["translateX(0)", null, null, "translateX(-50%)"],
              width: "3px",
              overflow: "hidden",
              height: timeline,
              backgroundColor: "lightTan",
            }}
          >
            <motion.div
              style={{
                width: "3px",
                height: lineHeight,
                backgroundColor: "#E1251B",
              }}
            ></motion.div>
          </Box>
          <Box sx={{ variant: "layout.wide" }}>
            <Flex
              as="ol"
              className="ag-timeline_list"
              ref={timelineRef}
              sx={{
                flexDirection: "column",
                listStyle: "none",
                margin: 0,
                padding: 0,
                "& li:nth-of-type(even) article": {
                  flexDirection: "row-reverse",
                  ".timelineBlock": {
                    opacity: 0,
                    transform: "translateX(300px)",
                    transition: "all 0.4s ease-in-out",
                  },
                },
                "& li:nth-of-type(odd) article": {
                  flexDirection: ["row-reverse", null, null, "initial"],
                  ".timelineBlock": {
                    opacity: 0,
                    transform: "translateX(-300px)",
                    transition: "all 0.4s ease-in-out",
                  },
                },
                "@media screen and (max-width: 610px)": {
                  "li:nth-of-type(odd) article": {
                    ".timelineBlock": {
                      opacity: 0,
                      transform: "translateX(300px)",
                      transition: "all 0.4s ease-in-out",
                    },
                  },
                },
                "& li.active::before": {
                  color: "white",
                },
                "& li.active svg circle.primary": {
                  fill: "secondary",
                  stroke: "none",
                },
                "& li.active svg circle.secondary": {
                  strokeDashoffset: 0,
                  stroke: "secondary",
                },
                "& li.active article .timelineBlock": {
                  opacity: 1,
                  transform: "translateX(0)",
                  transition: "all 0.4s ease-in-out",
                },
              }}
            >
              {timelineItems &&
                timelineItems.map((event, i) => {
                  return (
                    <Box
                      as="li"
                      key={i}
                      ref={addToYearRefs}
                      sx={{
                        position: "relative",
                        "&:before": {
                          content: `'${event.year}'`,
                          position: "absolute",
                          color: "white",
                          fontFamily: "heading",
                          fontSize: [1, null, 2],
                          top: "-12px",
                          zIndex: 30,
                          transition: "color 0.3s 0.3s",
                          left: ["0px", "6px", null, "49.25%", null],
                        },
                      }}
                    >
                      {/* Element that changes fill color */}
                      <Year />
                      {/* Element that changes stroke color */}
                      <Year front />
                      <TimelineBlock
                        content={event.content}
                        image={event.eventImage}
                        imageAlt={event.imageAlt}
                      />
                    </Box>
                  )
                })}
            </Flex>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Timeline
