/** @jsx jsx */
import { Swiper, SwiperSlide } from "swiper/react"
import { Box, jsx } from "theme-ui"
import SwiperCore, { Pagination } from "swiper"
import "swiper/css"
import "swiper/css/pagination"

import BlogCard from "components/BlogCard"
import SectionHeading from "components/SectionHeading"

type BlogPost = {
  title: string
  date: string
  srcUrl: string
}

interface FeaturedPostsProps {
  //enables sx prop to be passed down from parent
  className?: string
  posts: BlogPost[]
}

SwiperCore.use([Pagination])

// TODO: Make cards same height (swiper autoHeight not working)

const FeaturedPosts = ({ className, posts }: FeaturedPostsProps) => {
  return (
    <Box as="section" sx={{ my: 36 }}>
      <SectionHeading linkText="view all" linkUrl="/news">
        Recent News
      </SectionHeading>
      <Box
        sx={{
          mt: 16,
          mb: 8,
          maxWidth: "1200px",
          mx: "auto",
          ".swiper": { pb: 3 },
          ".swiper-slide": { height: "auto" },
        }}
      >
        <Swiper
          spaceBetween={15}
          slidesPerView={3}
          pagination={{
            el: ".pagination",
            clickable: true,
            type: "bullets",
          }}
          breakpoints={{
            320: {
              // slidesPerView: 1,
              centeredSlides: true,
              slidesPerView: 1.5,
            },
            // when window width is >= 480px
            480: {
              centeredSlides: false,
              slidesPerView: 2,
            },
            // when window width is >= 640px
            864: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
        >
          {posts.map(({ title, date, srcUrl }, index) => (
            <SwiperSlide key={index}>
              <BlogCard title={title} date={date} srcUrl={srcUrl} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className="pagination"
          sx={{
            textAlign: "center",
            mt: 3,
            "& .swiper-pagination-bullet": {
              backgroundColor: "transparent",
              borderWidth: "1px",
              borderColor: "primary",
              borderStyle: "solid",
              borderRadius: 0,
              opacity: 1,
              mr: 1,
            },
            "& .swiper-pagination-bullet-active": {
              backgroundColor: "primary",
            },
          }}
        />
      </Box>
    </Box>
  )
}

export default FeaturedPosts
